module.exports = {
  siteTitle: 'Load & Go Landscape Products', // Site title.
  siteTitleAlt: 'Load & Go Landscape Products', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png',
  siteUrl: 'https://loadandgolandscapeproducts.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links.
  siteDescription: 'From rock to mulch you can count on Load and Go for all of your hardscape needs. We make it easy to get what you need and be on your way. Stop by our showroom, select your product then have it delivered or bring your truck and we will load it for you.', // Website description used for RSS feeds/meta description tag.
  siteRss: '',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'load-and-go', // Disqus shortname.
  userName: '',
  userTwitter: '',
  userLocation: '',
  userDescription: '',
  copyright: 'Copyright © 2020 Load & Go Landscape Products. All Rights Reserved.', 
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  physicalAddress: '2135 East Division, Springfield, MO, 65803',
  phoneNumber: '417-864-4474',
  email:'loadandgolandscapeproducts@gmail.com',
  facebookUrl: '',
}
