import React from 'react';
import config from '../../../config';
import { FaPhone, FaEnvelope, FaDirections, FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer has-text-centered is-size-7-mobile">
      <div>
        &copy;{new Date().getFullYear()} {config.siteTitle} LLC. All Rights Reserved.
      </div>
    </footer>

  //   <footer className="footer has-background-light has-text-centered">
  //   <div className="columns is-multiline">
  //     <div className="column is-half center">
  //       <FaDirections />
  //       &nbsp;&nbsp;
  //       <a href={`http://maps.google.com/?q=`+ config.physicalAddress}>
  //         {config.physicalAddress}
  //       </a>
  //     </div>
  //     <div className="column is-half center">
  //       <FaPhone />
  //       &nbsp;&nbsp;
  // <a href={`tel:`+config.phoneNumber}>{config.phoneNumber}</a>
  //     </div>
  //     <div className="column is-half center">
  //       <FaEnvelope />
  //       &nbsp;&nbsp;
  //       <a href={`mailto:`+config.email}>{config.email}</a>
  //     </div>
  //     <div className="column is-half center">
  //       <FaFacebookF />
  //       &nbsp;&nbsp;
  //       <a href={config.facebookUrl}>Facebook</a>
  //     </div>
  //     <div className="column is-full center">
  //       {config.siteTitle} Copyright. All Rights Reserved.
  //     </div>
  //   </div>
  // </footer>

  )
}

export default Footer
